import React from "react"
import { Bold } from "../structure/theme/styles.component"
import { useViewPageTracking } from "../thirdParties/gtm/tagManager"
import Link from "../navigation/link.component"
import Block from "../../components/block/block.component"

export default function MentionsLegalesPage () {
  useViewPageTracking(`Juridique`, ``, `Mentions légales`)

  return (
    <>
      <Block blockTitle="Mentions légales" blockTitleSeo="h1" />

      <Block>
        <p data-testid="mentions_legales_p1">
          Mentions légales {APP_CONFIG.legalSiteName}
          <br />
          <br />
          Le site {APP_CONFIG.legalSiteName.replace(`www.`, ``)} est édité par la société HomeServe, SAS, dont le siège
          social est sis 9 rue Anna Marly - CS 80510 - 69365 Lyon Cedex 07, au capital de 40 000 €, immatriculée aux RCS
          de Lyon sous le numéro 438 424 384.
          <br />
          <br />
          <Bold>Téléphone :</Bold> 04 72 13 84 00
          <br />
          <Bold>Adresse e-mail :</Bold>
          {`\u0020`}
          webmestre@homeserve.fr
          <br />
          <Bold>Directeur de la publication :</Bold>
          {`\u0020`}
          Guillaume Huser en sa qualité de Président
          <br />
          <Bold>Hébergement : </Bold>Ce site est hébergé par Google Cloud Platform S8 rue de Londres 75009 Paris France
          {`\u0020`}
          <Link href="https://cloud.google.com" target="_blank" rel="noopener noreferrer">
            https://cloud.google.com
          </Link>
          .
        </p>
        <p data-testid="mentions_legales_p2">
          Les contrats d'assistance et d'assurance sont des services conçus, gérés et distribués par HomeServe SAS -
          Société de courtage en assurance. Les contrats d'assistance et/ou d'assurance sont assurés par la compagnie
          d'assurance AmTrust International Underwriters Designated Activity Company, N° d'Agrément 169384. HomeServe
          SAS est une société de courtage en assurance immatriculée à l'ORIAS sous le N° 07 023 309 (
          <Link href="https://www.orias.fr/" target="_blank">
            www.orias.fr
          </Link>
          ), dont l'activité relève de l'ACPR : 4, place de Budapest 75436 Paris Cedex 09.
          <br />
          <br />
          HomeServe n’est liée financièrement à aucune entreprise d’assurance. HomeServe travaille avec les sociétés
          d’assurance Allianz, AmTrust et Inter Partner Assistance.
          <br />
          L’ensemble des règles encadrant l’utilisation du site et de ses différents contenus est détaillé dans
          {`\u0020`}
          <Link route="conditionsJuridiques" data-testid="conditions_juridiques_link">
            les conditions juridiques du site
          </Link>
          .
        </p>
      </Block>
    </>
  )
}
